export function offset(elem) {
  let actualLeft = elem.offsetLeft;
  let actualTop = elem.offsetTop;
  let current = elem.offsetParent;
  let actulWidth = elem.offsetWidth;
  let actulHeight = elem.offsetHeight;
  while (current !== null) {
    actualLeft += current.offsetLeft;
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }
  return {
    left: actualLeft,
    top: actualTop,
    width: actulWidth,
    height: actulHeight
  }
}
export function dealPptUrl(pptUrl){
  let timeStop = '?time=' + new Date().getTime();
  let fileType = pptUrl.split('.').pop();
  let ncode =  'https://lms.leaplearner.education/api/doc/' + pptUrl.replace('/wopifiles/', '').replace('wopifiles/', '') + timeStop;
  let url = '';

  if (fileType === 'pdf') {
    url = 'https://drive.google.com/viewerng/viewer?url=' + ncode + '&embedded=true'
  } else {
    url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + ncode
  }

  return url
}

export function objectToUrl(object) {
  let arr = []
  for(let key in object) {
    arr.push(`${key}=${object[key]}`)
  }
  return arr.join('&')
}
