import React from 'react';
import Cookie from 'js-cookie';
import {connect} from 'react-redux';
import Modal from '../../containers/modal.jsx';
import styles from './timeTable.css';
import publics from '../public/public.css';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import {closeTimeTable} from '../../reducers/modals';
import LeapModal from '../leapModal/leapModal.jsx';
import leap from '../../lib/leap';
import analytics from '../../lib/analytics';
import {
    setTaskId
} from '../../reducers/material'
import {dealPptUrl} from '../utils'
class TimeTable extends React.Component{
    constructor(){
        super();
        this.state = {
            sectionList:[],//单元列表
            section:"",//单元
            subSectionList:[],//小节列表
            subsection:"",//小节
            materialList:[],
            material:"",//教材
            buttonDisable:true,
            renderPPT:false,
            localhost:"127.0.0.1",
            modalStyle:{width:'600px',height:'250px'},
            pptUrl:"",
            taskList:[],
            taskId:"",
            taskButtonDisable:true
        }
        bindAll(this, [
            'handleClose',
            'changeSection',
        ]);
    }
    componentDidMount(){
        this.getStudyUniList(); 
    }
    handleClose(){
        this.props.onCloseTimeTable();
    }
    changeMaterial(e){
        this.setState({
            material:e.target.value,
            section:"",
            sectionList:[],
            subsection:"",
            subSectionList:[],
            buttonDisable:true,
            taskButtonDisable:true,
            taskList:[],
            taskId:""
        });
        this.getSectionList(e.target.value);
    }
    changeSection(e){
        this.setState({
            section:e.target.value,
            subsection:"",
            subSectionList:[],
            buttonDisable:true,
            taskButtonDisable:true,
            taskList:[],
            taskId:""
        });
        this.getSubSectionList(e.target.value);
    }
    changeSubSection(e){
        if( e.target.value == "" ){
            this.setState({
                pptUrl:"",
                subsection:"",
                buttonDisable:true,
                taskButtonDisable:true,
                taskList:[],
                taskId:""
            });
        }else{
            let arr = e.target.value.split('|');
            let fileUrl = arr[0].split("=")[1];
            let id = arr[1].split("=")[1];
            this.setState({
                pptUrl:fileUrl,
                subsection:e.target.value,
                buttonDisable:false,
                taskButtonDisable:true,
                taskList:[],
                taskId:""
            });
            this.getTaskList(id)
        }
    }
    changeTaskId(e){
        if( e.target.value == "" ){
            this.setState({
                taskId:e.target.value,
                taskButtonDisable:true
            });
        }else{
            this.setState({
                taskId:e.target.value,
                taskButtonDisable:false
            });
        }
        
    }
    //获取教材
    getStudyUniList(){
        let url = leap.getServerURL()+"user/queryUserMaterialList?";
        let params = {
            userId:this.props.user.id,
            courseTypeId:4,
        };
        let urlArr = [];
        for(let key in params){
            urlArr.push(`${key}=${params[key]}`)
        }
        url = url+urlArr.join('&');
        
        fetch(url,{method:"POST",credentials: 'include'})
        .then(res=>res.json())
        .then(res=>{
            if(res.status=='0'){
                this.setState({
                    material:"",
                    materialList:res.data
                })
            }
        })

    }
    //获取单元
    getSectionList(value){
        if(!value) return;
        let url = leap.getServerURL()+"course/queryUnitCombobox?";
        let params = {
            userId:this.props.user.id,
            materialId:value
        };
        let urlArr = [];
        for(let key in params){
            urlArr.push(`${key}=${params[key]}`)
        }
        url = url+urlArr.join('&');

        fetch(url,{method:"POST",credentials: 'include'})
        .then(res=>res.json())
        .then(res=>{
            if(res.status=='0'){
                this.setState({
                    subsection:'',
                    sectionList:res.data
                })
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    //获取小节
    getSubSectionList(value){
        if(!value) return;
        let url = leap.getServerURL()+"course/querySectionByUnitCombobox?unitId="+value;
        fetch(url,{method:"POST",credentials: 'include'})
        .then(res=>res.json())
        .then(res=>{
            if(res.status=='0'){
                
                this.setState({
                    subSectionList:res.data
                })
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    //获取任务
    getTaskList(value){
        if(!value) return;
        let url = leap.getServerURL()+"/course/queryTaskBySectionCombobox?sectionId="+value;
        fetch(url,{method:"POST",credentials: 'include'})
        .then(res=>res.json())
        .then(res=>{
            if(res.status=='0'){
                this.setState({
                    taskId:'',
                    taskList:res.data
                })
            }
        })
        .catch(err=>{
            console.log(err)
        })
    }
    onRenderPPT(){
        if( this.state.pptUrl == "" ){
            alert("检测不到PPT");
            return;
        }
        let url = dealPptUrl(this.state.pptUrl)
        // let timeStop = '?time=' + new Date().getTime();
        // let FileUrl = this.state.pptUrl.replace('/wopifiles/','');
        // let ncode = encodeURIComponent('https://wopi'+process.env.RESOURCE+'/wopi/files/' + FileUrl + timeStop);
        // let splitArr = this.state.pptUrl.split(".");
        // let finaleStr = splitArr[splitArr.length-1];
        // let url = ""; 
        // if (['ppt', 'pptx'].includes(finaleStr.toLowerCase())) {
        //     url = 'https://office'+process.env.GO_IN+'/p/PowerPointFrame.aspx?WOPISrc=' + ncode + '&PowerPointView=ChromelessView';
        // } else if (['doc', 'docx'].includes(finaleStr.toLowerCase())) {
        //     url = 'https://office'+process.env.GO_IN+'/wv/wordviewerframe.aspx?WOPISrc=' + ncode;
        // } else {
        //     url = 'https://office'+process.env.GO_IN+'/wv/wordviewerframe.aspx?WOPISrc=' + ncode + '&PdfMode=1';
        // }
        window.open(url);
        this.props.onCloseTimeTable();
    }
    onOpenCode(){ //
        
        let url = leap.getServerURL() + 'scratch3/getScratchWork?workId=' + this.state.taskId + "&v="+new Date().getTime();
        // var url=leap.getServerURL()+"workapi/getWork?workId=" + this.state.taskId + "&v="+new Date().getTime();
        fetch(url,{
            method:"GET",
            credentials: 'include'
        })
        .then(res=>res.json())
        .then(res=>{
            console.log('怎么回是小老弟',res)
            if(parseInt(res.code)==0){
                this.props.onSetTaskId(this.state.taskId)
                this.props.onSetTaskId(this.state.taskId)
                this.selectwork( res.data )
            }
            alert("fail");
        })
        .catch(err=>{
            console.log("fetch error"+err);
            alert("fail");
        });
    }
    selectwork(work){ 
        if( !work.codeUrl || !work.id ) return;
        let url = "https://r"+process.env.RESOURCE+"/" + work.codeUrl + "?v=" + new Date().getTime();
        this.props.setProjectTitle(work.workName);
        this.props.onSelectWork( work.id);
        fetch(url ,{
            method:"GET",
            dataType:"blob"
        })
        .then(res=>res.blob())
        .then(blob=>{
            var reader = new FileReader();
            reader.onload =(e)=> {  
                this.initProject(e.target.result,work.workName);
            }; 
            reader.readAsArrayBuffer(blob);
        })
        .catch(err=>{
            console.log("fetch error"+err);
        });
    }
    initProject(data,filename){
        console.log('来了，兄弟',filename,data)
        try{
            this.props.vm.loadProject(data)
            .then(() => {
                analytics.event({
                    category: 'project',
                    action: 'Import Project File',
                    nonInteraction: true
                });
                this.props.onUpdateProjectTitle(filename);
                this.props.onCloseTimeTable();
            })
            .catch(error => {
                console.log(error);
                // this.props.onLoadingFinished(this.props.loadingState, false);
            });
        }catch(err){
            console.log(err)
        }
    }

    render(){
        return (
            <LeapModal
                title={ this.props.translation.material }
                handleClose = { this.handleClose }
                modalStyle={ this.state.modalStyle }
                // height={ this.state.height }
            >
                {
                    this.state.renderPPT?
                    <div className={styles.pptContainer}>
                        <iframe style={{height:"100%",width:"100%"}} id="pptIframe">
                        </iframe>
                    </div>:
                    <div>
                        <div className={styles.forbiden}>
                            <div className={styles.timeSection}>
                                <label>{this.props.translation.materialName}:</label>
                                <select name="" value={this.state.material} onChange={e=>{ this.changeMaterial(e) }}>
                                    <option value="">{this.props.translation.all}</option>
                                    {
                                        this.state.materialList.map(item=>{
                                            return (
                                                <option value={item.id} key={item.id}>{item.materialName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={styles.timeForm}>
                            <div className={styles.timeSection}>
                                <label>{ this.props.translation.unit }</label>
                                <select name="" value={this.state.section} onChange={e=>{ this.changeSection(e) }}>
                                    <option value="">{ this.props.translation.all }</option>
                                    {
                                        this.state.sectionList.map((item,index)=>{
                                            return (
                                                <option value={item.id} key={index}>{item.unitName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className={styles.timeSection}>
                                <label>{this.props.translation.section}</label>
                                <select name="" value={this.state.subsection} onChange={e=>{ this.changeSubSection(e) }}>
                                    <option value="">{this.props.translation.all}</option>
                                    {
                                        this.state.subSectionList.map((item,index)=>{
                                            item.dataKey = `fileUrl=${item.fileUrl}|id=${item.id}`
                                            return (
                                                <option value={item.dataKey} key={index}>{item.sectionName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className={styles.timeSection}>
                                <label>{this.props.translation.task}</label>
                                <select name="" value={this.state.taskId} onChange={e=>{ this.changeTaskId(e) }}>
                                    <option value="">{this.props.translation.all}</option>
                                    {
                                        this.state.taskList.map((item,index)=>{
                                            return (
                                                <option value={item.task_code_url} key={index}>{item.task_title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div>
                            <button className={publics.leapButton} disabled={this.state.buttonDisable} onClick={()=>{this.onRenderPPT()}}>{this.props.translation.openPpt}</button>                
                            <button className={publics.leapButton} disabled={this.state.taskButtonDisable} onClick={e=>{this.onOpenCode()}}>{this.props.translation.openCode}</button>                
                        </div>   
                    </div>
                }
                             
            </LeapModal>
        )
    }
}

const mapStateToProps = state => {
    const user = state.scratchGui && state.scratchGui.user && state.scratchGui.user.data;
    return {
        translation: state.locales.translation,
        user
    }
}
const mapDispatchToProps = dispatch => ({
    onCloseTimeTable:() => dispatch(closeTimeTable()), 
    onSetTaskId:id => dispatch(setTaskId(id)),
})
export default connect(mapStateToProps,mapDispatchToProps)(TimeTable);