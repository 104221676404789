import React from 'react';
import styles from './index.css'
import classNames from 'classnames';
import resourceImg from './images/resource.png'
import miniImg from './images/mini.png'
import leftImg from './images/air_left.png'
import rightImg from './images/air_right.png'
import { offset } from '../utils'
import {connect} from 'react-redux';
import bindAll from 'lodash.bindall';
import {
  closeLeapAir,
  openLeapAirMaterial,
  openLeapLoading,
  closeLeapLoading
} from '../../reducers/modals';
import {
  setMaterialName,
  setUnitList,
  setTaskId,
  setTaskTitle,
} from '../../reducers/material'
import {
  updateWorkId,
  updateLoadingStatus,
} from '../../reducers/user';
import {updateMessage} from '../../reducers/message'
import leap from '../../lib/leap';
import Animate from './animate'
import {
  requestNewProject
} from '../../reducers/project-state';
import analytics from '../../lib/analytics';
class AirModal extends React.Component{
  constructor(){
    super();
    this.state = {
      left: 200,
      top: 200,
      width: 1000,
      height: 642.5,
      minW: 600,
      minH: 400,
      contact: '',
      pointer:false,
      normalW: 80,
      IMG: null
    }
    bindAll(this,[
      'preTask',
      'nextTask',
      'handleClose',
      'getPicture',
      'openMaterial',
      'getDefaultWork',
      'selectHomework'
    ])
  }
  componentDidMount(){
    let _this = this;
    let head = document.querySelector('#leap_air_head');
    let top = document.querySelector('#lineT');
    let left = document.querySelector('#lineL');
    let bot = document.querySelector('#lineB');
    let right = document.querySelector('#lineR');
    // let leftT = document.querySelector('#pointLT');
    // let leftB = document.querySelector('#pointLB');
    // let rightT = document.querySelector('#pointRT');
    // let rightB = document.querySelector('#pointRB');
    let maxX = window.screen.availWidth;
    let minX = _this.state.minW;
    let maxY = window.screen.availHeight;
    let minY = _this.state.minH;
    let leftMove = e => {
      let x = e.clientX
      let _x = _this.state.left - x
      if (_this.state.width + _x < maxX && _this.state.width + _x > minX) {
        let width = _this.state.width + _x
        _this.setState({
          width,
          left:x,
          height: width * 9 / 16 + _this.state.normalW
        })
      }
    };
    let rightMove = e => {
      let x = e.clientX
      let _x = x - _this.state.width - _this.state.left
      if (_this.state.width + _x < maxX && _this.state.width + _x > minX) {
        let width = _this.state.width + _x
        _this.setState({
          width,
          height: width * 9 / 16 + _this.state.normalW
        })
      }
    };
    let upMove = e => {
      let y = e.clientY
      let _y = _this.state.top + 60 - y
      if (_this.state.height + _y < maxY && _this.state.height + _y > minY) {
        let height = _this.state.height+_y
        _this.setState({
          height,
          top: y - 60,
          width: (height - _this.state.normalW) * 16 / 9
        })
      }
    };
    let downMove = e => {
      let y = e.clientY
      let _y = y - _this.state.height - _this.state.top
      if (_this.state.height + _y < maxY && _this.state.height + _y > minY) {
        let height = _this.state.height+_y
        _this.setState({
          height,
          width: (height - _this.state.normalW) * 16 / 9
        })
      }
    };
    let xy;
    let _top;// 鼠标距离head顶距离
    let _left;// 鼠标距离head左距离
    const mousedown = (_e, type) => {
      _e.stopPropagation();
      xy = offset(document.querySelector('#airview'));
      if (type === 'head') {
        _top = _e.clientY - xy.top
        _left = _e.clientX - xy.left
      }
      _this.setState({
        pointer:true
      },()=>{
        window.onmousemove = function(e) {
          e.stopPropagation();
          switch (type) {
          case 'left': 
            leftMove(e);
            break;
          case 'leftT': 
            leftMove(e);
            upMove(e);
            break;
          case 'leftB': 
            leftMove(e);
            downMove(e);
            break;
          case 'right': 
            rightMove(e);
            break;
          case 'rightT': 
            rightMove(e);
            upMove(e);
            break;
          case 'rightB': 
            rightMove(e);
            downMove(e);
            break;
          case 'top': 
            upMove(e);
            break;
          case 'bot': 
            downMove(e);
            break;
          case 'head':
            _this.setState({
              top:e.clientY - _top,
              left:e.clientX - _left
            })
            break;
          }
        };
      })
    };
    // 拖拽
    head.onmousedown = e => mousedown(e, 'head');
    // 缩放
    top.onmousedown = e => mousedown(e, 'top');
    left.onmousedown = e => mousedown(e, 'left');
    bot.onmousedown = e => mousedown(e, 'bot');
    right.onmousedown = e => mousedown(e, 'right');
    // leftT.onmousedown = e => mousedown(e, 'leftT');
    // leftB.onmousedown = e => mousedown(e, 'leftB');
    // rightT.onmousedown = e => mousedown(e, 'rightT');
    // rightB.onmousedown = e => mousedown(e, 'rightB');
    window.onmouseup = e => {
      _this.setState({
        pointer:false
      })
      window.onmousemove = null;
    };
  }
  getDefaultWork(body) { // 新版本获取默认代码
    this.props.onOpenLeapLoading()
    this.props.onNewPage(false)
    let url = leap.getServerURL() + 'workapi/createWorkAfterLogin?' + body
    fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
    }).then(res=>res.json()).then(res=>{
        if (res.status === '0' && res.data && res.data.id) {
            this.props.onUpdateWorkId(res.data.id)
            this.props.onGetUserInfo({data:res.user});
            this.selectHomework( res.data )
        }
    }).catch(()=>{
        this.props.onGetUserInfo({data:{}});
        this.props.onCloseLeapLoading()
        
    })
  }
  selectHomework(work){
    console.log("通过taskId获取新作品",work)
    let _this = this;
    if( !work.codeUrl || !work.id ) return;
    let url = "https://r"+process.env.GO_IN+"/" + work.codeUrl + "?v=" + new Date().getTime();
    this.props.setProjectTitle(work.workName);
    this.props.onSelectWork( work.id);
    fetch(url ,{
        method:"GET",
        dataType:"blob"
    })
    .then(res=>res.blob())
    .then(blob=>{
        var reader = new FileReader();
        reader.onload =(e)=> {  
            let data =e.target.result;
            _this.props.vm.loadProject(data)
            .then(res => {
                analytics.event({
                    category: 'project',
                    action: 'Import Project File',
                    nonInteraction: true
                });
                _this.props.onUpdateProjectTitle( work.workName );
                _this.props.onCloseLeapLoading()
            })
            .catch(error => {
              _this.props.onCloseLeapLoading()
            });
        }; 
        reader.readAsArrayBuffer(blob);
    })
    .catch(err=>{
        console.log("fetch error"+err);
    });
  }
  getPicture(callback) {
    if (!this.state.IMG) {
      let head = offset(this.refs.air_head)
      let body = offset(this.refs.air_body)
      let cavs = document.createElement('canvas')
      let ctx = cavs.getContext('2d')
      cavs.width = head.width
      cavs.height = head.height + 6 + body.height
      ctx.fillStyle = '#4d97ff'
      ctx.fillRect(0, 0, head.width, head.height)
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, head.height + 6, body.width, body.height)
      let IMG = new Image()
      IMG.src = cavs.toDataURL('image/png')
      IMG.onload = () => {
        this.setState({
          IMG
        },()=>{
          callback()
        })
      }
    }else {
      callback()
    }
  }
  handleClose() {
    let _this = this
    _this.getPicture(()=>{
      this.props.closeAirModal()
      let app = document.getElementById('leap_page_wrapper')
      let a= new Animate(_this.state.IMG,app)
      let af = offset(app)
      a.init(af.width, af.height)
      _this.bag = offset(document.querySelector('#leap_air_bag'))
      _this.modal = offset(_this.refs.airview)
      a.zoomOut(_this.bag, _this.modal)
    })
  }
  openMaterial(){
    console.log('打开教材')
    console.log(this.props.material)
    let url = leap.getServerURL() + 'mc/getUnitCombobox?' + 'materialId=' + this.props.material.materialId
    fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json'}
    }).then(res=>res.json()).then(res=>{
        if (res.status === '0' ) {
          console.log(res)
          if(res.material) this.props.onSetMaterialName(res.material.materialName)
          if(res.data) this.props.onSetUnitList(res.data)
          this.props.openMaterial();
        }else{
          this.props.onUpdateMessage({
            type:'warning',
            message: this.props.translation.noMaterial
          }) 
        }
    })
  }
  preTask(){
    // console.log(this.props.material)
    let list = this.props.material.taskList;
    let id = this.props.material.taskId;
    let newIndex;
    if (list.length < 2) {
      this.props.onUpdateMessage({
        type:'warning',
        message:this.props.translation.firstMaterial
      }) 
    } else {
      list.forEach((l,i)=>{
        if (l.id == id) {
          if (i===0) {
            this.props.onUpdateMessage({
              type:'warning',
              message:this.props.translation.firstMaterial
            })
          }else{
            newIndex = i - 1 
          }
        }
      })
      if(newIndex!=undefined){
        this.props.onSetTaskTitle(list[newIndex].task_title)
        this.props.onSetTaskId(list[newIndex].id)
        this.getDefaultWork(`type=3&sectionId=${this.props.material.sectionId}&taskId=${list[newIndex].id}`)
      }
    }
  }
  nextTask(){
    let list = this.props.material.taskList;
    let id = this.props.material.taskId;
    let newIndex;
    if (list.length < 2) {
      this.props.onUpdateMessage({
        type:'warning',
        message: this.props.translation.lastMaterial
      }) 
    } else {
      list.forEach((l,i)=>{
        if (l.id == id) {
          if (i===list.length-1) {
            this.props.onUpdateMessage({
              type:'warning',
              message: this.props.translation.lastMaterial
            })  
          }else{
            newIndex = i + 1 
          }
        }
      })
      if(newIndex!=undefined){
        this.props.onSetTaskTitle(list[newIndex].task_title)
        this.props.onSetTaskId(list[newIndex].id)
        this.getDefaultWork(`type=3&sectionId=${this.props.material.sectionId}&taskId=${list[newIndex].id}`)
      }
    }
  }
  render(){
    return(
      <div id="airview" ref="airview" className={styles.leapAirModal} style={{
        'left': this.state.left + 'px',
        'top': this.state.top + 'px',
        'width': this.state.width + 'px',
        'height': this.state.height + 'px',
        'display':this.props.LeapAirVisible ? null : 'none'
      }}>
        <div id="leap_air_head" ref="air_head" className={styles.leapAirHead}>
          <img src={resourceImg} alt="" className={styles.leapAirHeadLeft} onClick={this.openMaterial}/>
          <div className={styles.leapAirHeadRight} onClick={this.handleClose}>
            <img src={miniImg} alt=""  />
          </div>
          <div className={styles.leapAirHeadMid}>
            {
              this.props.material.sectionName!='undefined'?<span>{this.props.material.sectionName}</span>:null
            }
            
            <img src={leftImg} alt="" onClick={this.preTask}/>
            <span>{this.props.material.taskTitle}</span>
            <img src={rightImg} alt="" onClick={this.nextTask}/>
          </div>
        </div>
        <div className={styles.leapAirBody} ref="air_body">
          {/* 上下左右4条线可拖动 */}
          <div id="lineT" className={classNames(styles.leapAirTLine,styles.leapAirLine)}></div>
          <div id="lineL" className={classNames(styles.leapAirLLine,styles.leapAirLine)}></div>
          <div id="lineB" className={classNames(styles.leapAirBLine,styles.leapAirLine)}></div>
          <div id="lineR" className={classNames(styles.leapAirRLine,styles.leapAirLine)}></div>
          {/* 左上 右上 左下 右下 4个点可拖动 */}
          {/* <div id="pointLT" className={classNames(styles.leapAirLtPoint,styles.leapAirPoint)}></div>
          <div id="pointLB" className={classNames(styles.leapAirLbPoint,styles.leapAirPoint)}></div>
          <div id="pointRT" className={classNames(styles.leapAirRtPoint,styles.leapAirPoint)}></div>
          <div id="pointRB" className={classNames(styles.leapAirRbPoint,styles.leapAirPoint)}></div> */}
          <div className={classNames(styles.leapAirPlay,{
            [styles.leapPointerNone]:this.state.pointer
          })}>
            <iframe src={this.props.material.pptUrl} frameBorder='0'></iframe>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    LeapAirVisible:state.scratchGui.modals.leapAir,
    material:state.scratchGui.material,
    translation: state.locales.translation
  }
}
const mapDispatchToProps = dispatch => ({
  closeAirModal:() => dispatch(closeLeapAir()), 
  openMaterial:() => dispatch(openLeapAirMaterial()), 
  onSetMaterialName:name => dispatch(setMaterialName(name)), 
  onSetUnitList:list => dispatch(setUnitList(list)),
  onSetTaskTitle:title => dispatch(setTaskTitle(title)),
  onSetTaskId:id => dispatch(setTaskId(id)),
  onUpdateMessage:data => dispatch(updateMessage(dispatch,data)),
  onUpdateWorkId: id => dispatch(updateWorkId(id)),
  onGetUserInfo:(data)=>dispatch(updateLoadingStatus(data)),
  onNewPage:needSave =>{
    dispatch(requestNewProject(needSave))
    dispatch(updateWorkId(0));
  },
  onOpenLeapLoading: () => dispatch(openLeapLoading()),
  onCloseLeapLoading: () => dispatch(closeLeapLoading()),
})
export default connect(mapStateToProps,mapDispatchToProps)(AirModal);